import { FC } from 'react'
import { OrderEntity } from '../../../types'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { Checkbox, TextField } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { useIntl } from 'react-intl'

/**
 * Static list of supported order statuses
 */
const orderStatusTypes: OrderEntity['status'][] = [
  'NEW',
  'PLACED',
  'ACCEPTED',
  'PREPARING',
  'READY',
  'DELIVERING',
  'COMPLETED',
  'REJECTED',
  'CANCELLED',
  'DELIVERY_FAILED',
]

/**
 *
 */
export interface OrderStatusPickerProps
  extends Omit<
    BasePickerProps<OrderEntity['status'], true, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const OrderStatusPicker: FC<OrderStatusPickerProps> = (props) => {
  const intl = useIntl()
  return (
    <BasePicker
      options={orderStatusTypes}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: 0, textTransform: 'uppercase' }}>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.formatMessage({ id: 'label_status' })}
        />
      )}
      {...props}
    />
  )
}
