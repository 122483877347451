import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material'
import { FC, useState } from 'react'

export interface MoneyTextFieldProps extends StandardTextFieldProps {
  min?: number
  max?: number
}

/**
 * Componet that allows the user to input a money value
 */
export const MoneyTextField: FC<MoneyTextFieldProps> = (props) => {
  const { min, max, ...textFieldProps } = props
  return (
    <TextField
      {...textFieldProps}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputProps: {
          min: min ?? 0,
          max: max ?? 1000000,
          step: 0.01,
          ...props.InputProps?.inputProps,
        },
        ...props.InputProps,
      }}
      type="number"
      defaultValue={0}
    />
  )
}
