import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppLoader,
  ClickableIcon,
  NoRecordFound,
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
} from '../../../../../components'
import { useModifierGroups } from '../../../../../hooks'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useIntl } from 'react-intl'
import { FormattedDateTime } from '../../../../../components/FormattedDateTime'
import { shortenText } from '../../../../util'
import { Fragment } from 'react'

export function ModifierGroups() {
  const { locationId } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()

  const modifierGroups = useModifierGroups(locationId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroups.fetchNextPage()
      }
    },
  })
  const records = modifierGroups.data?.pages?.map((value) => value.data).flat()
  const sortedRecords = records?.sort(
    (a: any, b: any) => a?.position - b?.position
  )

  if (modifierGroups.isLoading) {
    return <AppLoader />
  }
  if (modifierGroups.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  return (
    <>
      {modifierGroups.isSuccess && !sortedRecords?.length ? (
        <NoRecordFound title="ModifierGroups" />
      ) : (
        <StyledTablePaperWrapper sx={{ mt: 1 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_modifiers' })}
                  </TableCell>
                  <TableCell sx={{ width: '300px' }}>
                    {intl.formatMessage({ id: 'label_last_modified_at' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRecords?.map((modifierData, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() =>
                        navigate(
                          `/app/locations/${locationId}/catalog/modifier-group/${modifierData.id}/view`
                        )
                      }
                    >
                      <TableCell>
                        {modifierData.name?.length > 25 ? (
                          <Tooltip title={modifierData.name}>
                            <Typography variant="body2">
                              {shortenText(modifierData.name, 25)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          modifierData.name
                        )}
                      </TableCell>
                      <TableCell>
                        {modifierData.modifiers
                          .slice(0, 3)
                          .map((data, index) => {
                            return (
                              <Fragment key={index}>
                                <Chip label={data.name} sx={{ margin: 0.3 }} />
                                {index === 2 &&
                                  modifierData.modifiers.length > 3 && (
                                    <Chip label="..." sx={{ margin: 0.3 }} />
                                  )}
                              </Fragment>
                            )
                          })}
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={modifierData.updatedAt} />
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ display: 'flex' }}>
                          <ClickableIcon
                            handleNavigate={() =>
                              navigate(
                                `/app/locations/${locationId}/catalog/modifier-group/${modifierData.id}/view`
                              )
                            }
                            children={<VisibilityOutlinedIcon />}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {modifierGroups.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => modifierGroups.fetchNextPage()}
                disabled={modifierGroups.isFetching}
                variant="outlined"
                color="primary"
              >
                {modifierGroups.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </StyledTablePaperWrapper>
      )}
    </>
  )
}
