import { FC } from 'react'
import {
  DateTimePicker as BaseDateTimePicker,
  DateTimePickerProps as BaseDateTimePickerProps,
} from '@mui/x-date-pickers'

/**
 * Export props of the DateTimePicker component
 */
export interface DateTimePickerProps extends BaseDateTimePickerProps<Date> {}

/**
 * DateRangePicker component
 */
export const DateTimePicker: FC<DateTimePickerProps> = (props) => {
  // @todo get timezone from user settings and apply it as a default value
  return <BaseDateTimePicker {...props} />
}
