import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { api } from '../services/api'
import { String } from 'aws-sdk/clients/cloudsearchdomain'

export interface UserResellerOrderProps {
  resellerId: string
  filters?: {
    query?: string
    accountIds?: string[]
    locationIds?: string[]
    orderIds?: string[]
    id: any
  }
}

export const useResellerDeliveries = (
  props: UserResellerOrderProps,
  options: Omit<
    UseInfiniteQueryOptions<any, Error, any, any, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const token = useAccessToken()
  const { resellerId, filters } = props
  const baseKey = ['resellers', resellerId, 'deliveries', filters]
  if (props.filters && props.filters.query) {
    baseKey.push(props.filters.query)
  }
  return useInfiniteQuery(
    baseKey,
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/deliveries`, {
          params: {
            ...props.filters,
            nextPageKey: ctx.pageParam,
          },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageKey
      },
      keepPreviousData: true,
      cacheTime: 0,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerDeliveryCreateMutation = (props: {
  resellerId: string
}) => {
  const token = useAccessToken()

  return useMutation((data: any) =>
    api
      .post(`/v1/resellers/${props.resellerId}/deliveries`, data, {
        headers: { Authorization: `Bearer ${token?.access_token}` },
      })
      .then(({ data }) => data)
  )
}

export const useResellerDeliveryById = (
  props: { resellerId: string; deliveryId: string },
  options?: Omit<UseQueryOptions<any, Error, any, any>, 'queryKey' | 'queryFn'>
) => {
  const token = useAccessToken()
  const { resellerId, deliveryId } = props

  return useQuery(
    ['resellers', resellerId, 'deliveries', deliveryId],
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/deliveries/${deliveryId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerDeliveryAudits = (
  props: { resellerId: string; deliveryId: string },
  options: Omit<UseQueryOptions<any, Error, any, any>, 'queryKey' | 'queryFn'>
) => {
  const token = useAccessToken()
  const { resellerId, deliveryId } = props

  return useInfiniteQuery(
    ['resellers', resellerId, 'deliveries', deliveryId, 'audits'],
    (ctx) =>
      api
        .get(`/v1/resellers/${resellerId}/deliveries/${deliveryId}/audits`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(ctx.signal)?.token,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!token?.access_token,
    }
  )
}

export const useResellerDeliveryDispatchMutation = (props: {
  resellerId: string
  deliveryId: string
}) => {
  const token = useAccessToken()

  return useMutation((data: any) =>
    api
      .post(
        `/v1/resellers/${props.resellerId}/deliveries/${props.deliveryId}/dispatch`,
        data,
        { headers: { Authorization: `Bearer ${token?.access_token}` } }
      )
      .then(({ data }) => data)
  )
}

export const useResellerDeliveryRefreshMutation = (props: {
  resellerId: string
  deliveryId: string
}) => {
  const token = useAccessToken()

  return useMutation((data: any) =>
    api
      .post(
        `/v1/resellers/${props.resellerId}/deliveries/${props.deliveryId}/refresh`,
        data,
        { headers: { Authorization: `Bearer ${token?.access_token}` } }
      )
      .then(({ data }) => data)
  )
}
