import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Snackbar,
  SnackbarContentProps,
} from '@mui/material'
import {
  AppLoader,
  AppWarningDialog,
  ClickableIcon,
  CreateButtonFlexEnd,
  ModifierListTable,
  NoRecordFound,
  ShowOnlineView,
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
} from '../../../../../../components'
import { MenuModifierEntity } from '../../../../../../types'
import { useNavigate, useParams } from 'react-router-dom'
import {
  defaultImageUrl,
  formatUtcDate,
  getCurrencySymbol,
  shortenText,
  tableImageStyles,
} from '../../../../../util'
import { useIntl } from 'react-intl'
import {
  useMenuModifierDelete,
  useMenuModifierUpdate,
  useMenuModifiers,
} from '../../../../../../hooks'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Fragment, useEffect, useState } from 'react'

export function MenuModifiers() {
  const navigate = useNavigate()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const currency = getCurrencySymbol()
  const { locationId, menuId } = useParams()
  const modifiers = useMenuModifiers(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifiers.fetchNextPage()
        setIsFetching(true)
      }
      setIsFetching(false)
      setModifierState(data?.pages?.map((value: any) => value.data).flat())
    },
  })
  const [isFetching, setIsFetching] = useState(true)
  const modifierDelete = useMenuModifierDelete(locationId!, menuId!)
  const records = modifiers.data?.pages?.map((value) => value.data).flat()

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedModifier, setSelectedModifier] = useState<any>(
    {} as MenuModifierEntity
  )
  const [selectedModifierId, setSelectedModifierId] = useState(undefined)
  const ModifierUpdateMutation: any = useMenuModifierUpdate(
    locationId!,
    menuId!,
    selectedModifierId!
  )
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(false)
  const [deletedItem, setDeletedItem] = useState<any>(null)
  const [modifierState, setModifierState] = useState(records)
  const [deletedList, setDeletedList] = useState<string[]>([])
  const [deletedIndex, setDeletedIndex] = useState<any>(null)
  const [sortedRecords, setSortedRecords] = useState<any>([])
  const undoDuration = 3000
  useEffect(() => {
    setSortedRecords(
      modifierState?.sort((a: any, b: any) => a.position - b.position)
    )
  }, [modifierState])

  // const handleDeleteConfirmed = async () => {
  //   modifierDelete.mutate(selectedModifier.id, {
  //     onSuccess: () => {
  //       setShowConfirmationDialog(false)
  //     },
  //   })
  // }
  const handleDeleteConfirmed = async () => {
    setShowConfirmationDialog(false)
    setShowUndoSnackbar(true)
    setDeletedList([...deletedList, selectedModifier.id])

    const updatedMenu = modifierState?.filter(
      (modifier: any) =>
        ![...deletedList, selectedModifier.id].includes(modifier.id)
    )
    const deletedItem = records?.find(
      (modifier) => modifier.id === selectedModifier.id
    )

    const deletedItemIndex = modifierState?.findIndex(
      (modifier: any) => modifier.id === deletedItem?.id
    )

    setDeletedIndex(deletedItemIndex)

    setDeletedItem(deletedItem)
    setModifierState(updatedMenu as any)
  }

  const handleUndo = () => {
    setShowUndoSnackbar(false)
    setHighlightedRow(true)
    setDeletedList([...deletedList.slice(0, -1)])
    if (deletedItem) {
      // Create a copy of the menuItems array
      const updatedMenu = modifierState as any

      if (deletedIndex !== -1) {
        updatedMenu.splice(deletedIndex, 0, deletedItem)

        const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
          return (
            updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
          )
        })

        setModifierState(uniqueMenu)
      }
    }
  }

  const afterSnackBarClosed = () => {
    setShowUndoSnackbar(false)
    //actuall deletion
    modifierDelete.mutate(selectedModifier.id, {
      onSuccess: () => {
        // setShowConfirmationDialog(false)
      },
    })
  }

  const action = (
    <Fragment>
      <Button
        variant="text"
        color="info"
        size="medium"
        onClick={handleUndo}
        style={{ textTransform: 'none' }}
      >
        Undo
      </Button>
    </Fragment>
  )
  useEffect(() => {
    if (highlightedRow !== false) {
      const timeoutId = setTimeout(() => {
        setHighlightedRow(false)
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [highlightedRow])

  useEffect(() => {
    let timeoutId: any
    if (showUndoSnackbar) {
      timeoutId = setTimeout(() => {
        setShowUndoSnackbar(false)
        afterSnackBarClosed()
      }, undoDuration)
    }
    return () => clearTimeout(timeoutId)
  }, [showUndoSnackbar, setShowUndoSnackbar, afterSnackBarClosed])

  useEffect(() => {
    selectedModifierId !== undefined &&
      ModifierUpdateMutation.mutate(
        { showOnline: !selectedModifier?.showOnline },
        {
          onSuccess: () => {
            setSelectedModifierId(undefined)
          },
        }
      )
  }, [selectedModifierId])
  const handleShowOnlineChange = (e: any, item: any) => {
    setSelectedModifierId(item.id)
    setSelectedModifier(item)
  }

  if (modifiers.isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          {isFetching
            ? `${intl.formatMessage({ id: 'lable_fetching_data' })}`
            : ''}
        </Typography>
        <CreateButtonFlexEnd
          handleClick={() => setOpen(true)}
          buttonType="modifier"
        />
      </Box>
      {modifiers.isSuccess && !records?.length ? (
        <NoRecordFound title="Modifier" />
      ) : (
        <StyledTablePaperWrapper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_image' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_show_online' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_price' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_last_modified_at' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRecords?.map((modifier: any, index: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{
                        cursor: 'default !important',
                        backgroundColor:
                          highlightedRow &&
                          deletedItem?.name === modifier.name &&
                          index == deletedIndex
                            ? '#fff1d5'
                            : 'inherit',
                        transition: 'background-color 0.5s linear',
                      }}
                      onClick={() =>
                        navigate(
                          `/app/locations/${locationId}/menu/${menuId}/modifier/${modifier.id}`
                        )
                      }
                    >
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <img
                            style={{ ...tableImageStyles }}
                            src={
                              modifier.imageUrl
                                ? modifier.imageUrl
                                : defaultImageUrl
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>{shortenText(modifier.name, 25)}</TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <ShowOnlineView
                          disabled={isFetching || false}
                          item={modifier}
                          handleShowOnlineChange={(e: any) =>
                            handleShowOnlineChange(e, modifier)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {currency} {''}
                        {modifier.price
                          ? (
                              parseFloat(modifier.price.toString()) / 100
                            ).toFixed(2)
                          : '0.00'}
                      </TableCell>

                      <TableCell>{formatUtcDate(modifier.createdAt)}</TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ display: 'flex' }}>
                          <ClickableIcon
                            handleNavigate={() =>
                              navigate(
                                `/app/locations/${locationId}/menu/${menuId}/modifier/${modifier.id}`
                              )
                            }
                            disabled={isFetching}
                            children={<EditOutlined />}
                          />
                          <ClickableIcon
                            handleNavigate={() => [
                              setShowConfirmationDialog(true),
                              setSelectedModifier(modifier),
                              showUndoSnackbar && afterSnackBarClosed(),
                            ]}
                            disabled={isFetching}
                            children={<DeleteOutline />}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {modifiers.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => modifiers.fetchNextPage()}
                disabled={modifiers.isFetching}
                variant="outlined"
                color="primary"
              >
                {modifiers.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </StyledTablePaperWrapper>
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth={'lg'}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <ModifierListTable
          handleClose={() => setOpen(false)}
          locationId={locationId!}
        />
      </Dialog>
      <AppWarningDialog
        name={selectedModifier.name}
        source="modifierGroups"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
      <Snackbar
        open={showUndoSnackbar}
        autoHideDuration={undoDuration}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return
          }
          afterSnackBarClosed()
        }}
        message={
          <Typography variant="body2" sx={{ color: 'black' }}>
            Deleted{' '}
            <span style={{ fontWeight: 'bold' }}>{deletedItem?.name}</span>
          </Typography>
        }
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ContentProps={{
          sx: {
            backgroundColor: 'white',
          } as SnackbarContentProps['sx'],
        }}
      />
    </>
  )
}
