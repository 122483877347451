import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
  AppLoader,
  AppWarningDialog,
  ClickableIcon,
  CreateButtonFlexEnd,
} from '../../components'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAccounts, useLocationDelete, useLocations } from '../../hooks'
import { DeleteOutline, EditOutlined, NoteAdd } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { LocationEntity } from '../../types'
import { useCreateOrderSample } from '../../hooks/useCreateOrderSample'
import { toast } from 'react-toastify'

export function Locations() {
  const navigate = useNavigate()
  const intl = useIntl()
  const locations = useLocations()
  const orderCreateSample = useCreateOrderSample()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState({} as LocationEntity)
  const locationDelete = useLocationDelete(selectedLocation?.id)
  const records = locations.data?.pages.map((value) => value.data).flat()
  const accountMutation = useAccounts()
  useEffect(() => {
    if (accountMutation.isSuccess && !accountMutation.data?.data?.length) {
      navigate('/app/accounts/new')
    }
  }, [accountMutation.isSuccess])
  useEffect(() => {
    if (
      !records?.length &&
      accountMutation.data?.data?.length &&
      !locations.isLoading
    ) {
      navigate('/app/locations/new')
    }
  }, [locations.isSuccess])

  const handleDeleteConfirmed = () => {
    locationDelete.mutate(undefined, {
      onSuccess: () => {
        setShowConfirmationDialog(false)
      },
    })
  }

  if (locations.isLoading) {
    return <AppLoader />
  }
  if (locations.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('new')}
        >
          {intl.formatMessage({ id: `label_create_location` })}
        </Button>
      </Box>

      {locations.isSuccess && locations.data?.pages[0]?.data?.length === 0 ? (
        <Navigate to={'/app/locations/new'} replace />
      ) : (
        <StyledTablePaperWrapper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_address' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((locationData, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => navigate(`${locationData.id}/apps`)}
                    >
                      <TableCell>{locationData?.name}</TableCell>
                      <TableCell>{locationData?.address?.address}</TableCell>
                      <TableCell
                        onClick={(e) => e.stopPropagation()}
                        sx={{ cursor: 'default' }}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'action_edit',
                            })}
                          >
                            <ClickableIcon
                              handleNavigate={() =>
                                navigate(`${locationData.id}`)
                              }
                              children={<EditOutlined />}
                            />
                          </Tooltip>
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'action_delete',
                            })}
                          >
                            <ClickableIcon
                              handleNavigate={() => [
                                setShowConfirmationDialog(true),
                                setSelectedLocation(locationData),
                              ]}
                              children={<DeleteOutline />}
                            />
                          </Tooltip>
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'action_create_test_order',
                            })}
                          >
                            <ClickableIcon
                              handleNavigate={() => {
                                orderCreateSample.mutate(
                                  {
                                    locationId: locationData.id!,
                                  },
                                  {
                                    onSuccess: () => {
                                      toast.success('Test order created')
                                    },
                                  }
                                )
                              }}
                              children={<NoteAdd />}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {locations.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => locations.fetchNextPage()}
                disabled={locations.isFetching}
                variant="outlined"
                color="primary"
              >
                {locations.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </StyledTablePaperWrapper>
      )}
      <AppWarningDialog
        loading={locationDelete.isLoading}
        name={selectedLocation?.name}
        source="location"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
    </>
  )
}
