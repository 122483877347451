import { useParams } from 'react-router-dom'
import { useResellerDeliveries } from '../../../hooks'
import { DeliveriesTable } from './DeliveriesTable'
import { FC, useState } from 'react'
import { Box, Button } from '@mui/material'
import { DeliveryCreateDialog } from './DeliveryCreateDialog'

export const ResellerDeliveries: FC = () => {
  let { resellerId } = useParams()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  /**
   * Create the data query to fetch the orders
   */
  const deliveries = useResellerDeliveries(
    { filters: undefined, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  /**
   * Handle the close of the create dialog
   */
  const handleOnCreateDialogClose = () => {
    setCreateDialogOpen(false)
  }

  return (
    <>
      {createDialogOpen && (
        <DeliveryCreateDialog open onClose={handleOnCreateDialogClose} />
      )}

      {/* Action Bar that alighs the action buttons along the right side */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setCreateDialogOpen(true)}
        >
          Create Delivery
        </Button>
      </Box>

      <DeliveriesTable deliveries={deliveries} />
    </>
  )
}
