import { FC } from 'react'
import { Box, useTheme } from '@mui/material'

// CenteredBox Component
export const CenteredBox: FC = ({ children }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: theme.spacing(4),
      }}
    >
      {children}
    </Box>
  )
}
