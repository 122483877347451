import { CheckCircleOutline, WarningAmber } from '@mui/icons-material'
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  Typography,
} from '@mui/material'
import { FormattedRelativeTime } from '../../../components/FormattedRelativeTime'
import { FormattedMoney } from '../../../components/FormattedMoney'
import { Schema } from '../../../../../../pos-hub/runtime/types'
import { FC } from 'react'
import { UseQueryResult } from 'react-query'

export type DeliveryQuote = Schema<'DeliveryEntity'>['quotes'][0]

/**
 * Delivery Quote List Props
 */
export interface DeliveryQuoteListProps extends MenuListProps {
  delivery: UseQueryResult<{ data: Schema<'DeliveryEntity'> }>
  onQuoteSelect?: (quote: DeliveryQuote) => void
  disabled?: boolean
}

/**
 *
 */
export const DeliveryQuoteList: FC<DeliveryQuoteListProps> = (props) => {
  if (props.delivery.isLoading || props.delivery.isError) {
    return null
  }

  return (
    <MenuList {...props}>
      {props.delivery.data?.data.quotes.map((quote) => (
        <DeliveryQuoteListItem
          key={quote.quoteId}
          quote={quote}
          disabled={props.disabled}
          onClick={() => props.onQuoteSelect?.(quote)}
          isDispatched={
            props.delivery.data?.data.dispatched.findIndex(
              (dispatch) => dispatch.quoteId === quote.quoteId
            ) !== -1
          }
        />
      ))}
    </MenuList>
  )
}

export interface DeliveryQuoteListItemProps extends MenuItemProps {
  quote: DeliveryQuote
  isDispatched?: boolean
  disabled?: boolean
}

export const DeliveryQuoteListItem: FC<DeliveryQuoteListItemProps> = (
  props
) => {
  /**
   * If the quote is an error, return the error list item
   */
  if (props.quote.status === 'ERROR') {
    return <DeliveryQuoteListErrorItem {...props} disabled />
  }

  /**
   * Determine if the quote is expired
   */
  const nowDt = new Date()
  const quoteExpiresAtDt = new Date(props.quote.quoteExpiresAt)
  const isExpired = quoteExpiresAtDt < nowDt
  const isExpiringSoon =
    quoteExpiresAtDt < new Date(nowDt.getTime() + 1000 * 60 * 3)

  /**
   * Color Status
   */
  let icon = <CheckCircleOutline fontSize="small" color="success" />
  let secondaryText = (
    <>
      Expires{' '}
      {props.quote.quoteExpiresAt && (
        <FormattedRelativeTime value={props.quote.quoteExpiresAt} />
      )}
    </>
  )
  if (isExpiringSoon) {
    icon = <WarningAmber fontSize="small" color="warning" />
  }
  if (isExpired) {
    icon = <WarningAmber fontSize="small" color="error" />
  }

  return (
    <MenuItem
      disabled={props.disabled || props.isDispatched || isExpired}
      onClick={props.onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText secondary={secondaryText}>
        {props.quote.applicationName}
      </ListItemText>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        <FormattedMoney
          value={props.quote.price / 100}
          currency="USD"
          decimalValue
        />
      </Typography>
    </MenuItem>
  )
}

export interface DeliveryQuoteListErrorItemProps extends MenuItemProps {
  quote: DeliveryQuote
  disabled?: boolean
}

export const DeliveryQuoteListErrorItem: FC<
  DeliveryQuoteListErrorItemProps
> = ({ quote, disabled, onClick }) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <ListItemIcon>
        <WarningAmber fontSize="small" color="error" />
      </ListItemIcon>
      <ListItemText secondary={quote.error}>
        {quote.applicationName ?? 'Unknown Application'}
      </ListItemText>
    </MenuItem>
  )
}

export interface DeliveryQuoteListSuccessItemProps extends MenuItemProps {
  quote: DeliveryQuote
  disabled?: boolean
}

export const DeliveryQuoteListSuccessItem: FC<
  DeliveryQuoteListSuccessItemProps
> = ({ quote, disabled, onClick }) => {
  const nowDt = new Date()
  const quoteExpiresAtDt = new Date(quote.quoteExpiresAt)
  const isQuoteError = quote.status === 'ERROR'
  const isQuiteExpired = quoteExpiresAtDt < nowDt
  const isExpiringSoon =
    quoteExpiresAtDt < new Date(nowDt.getTime() + 1000 * 60 * 3)

  let icon = <CheckCircleOutline fontSize="small" color="success" />
  let secondaryText = (
    <>
      Expires{' '}
      {quote.quoteExpiresAt && (
        <FormattedRelativeTime value={quote.quoteExpiresAt} />
      )}
    </>
  )
  if (isExpiringSoon) {
    icon = <WarningAmber fontSize="small" color="warning" />
  }
  if (isQuiteExpired) {
    icon = <WarningAmber fontSize="small" color="error" />
  }
  if (isQuoteError) {
    icon = <WarningAmber fontSize="small" color="error" />
    secondaryText = <>{quote.error}</>
  }

  return (
    <MenuItem
      disabled={disabled || isQuiteExpired || isQuoteError}
      onClick={onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText secondary={secondaryText}>
        {quote.applicationName ?? 'Unknown Application'}
      </ListItemText>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {isQuoteError ? (
          <></>
        ) : (
          <FormattedMoney
            value={quote.price / 100}
            currency="USD"
            decimalValue
          />
        )}
      </Typography>
    </MenuItem>
  )
}
