import { FC } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { StyledTablePaperWrapper } from '../../../components'
import { FormattedRelativeTime } from '../../../components/FormattedRelativeTime'
import {
  Typography,
  Tooltip,
  Box,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { DeliveryStatusLabel } from './DeliveryStatusLabel'
import { useNavigate } from 'react-router-dom'

export interface DeliveriesTableProps {
  deliveries: UseInfiniteQueryResult<any, Error>
}

export const DeliveriesTable: FC<DeliveriesTableProps> = (props) => {
  const navigate = useNavigate()
  const deliveries = props.deliveries.data?.pages.flatMap((page) => page.data)

  const handleOnRowClick = (deliveryId: string) => {
    navigate(deliveryId)
  }

  return (
    <StyledTablePaperWrapper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Provider</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Route</TableCell>
              <TableCell>Pickup ETA</TableCell>
              <TableCell>Dropoff ETA</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveries?.map((delivery) => (
              <TableRow onClick={() => handleOnRowClick(delivery.id)}>
                <TableCell>
                  <Avatar variant="square" sx={{ width: 50, height: 50 }}>
                    DD
                  </Avatar>
                </TableCell>
                <TableCell>
                  <DeliveryStatusLabel status={delivery.status} />
                </TableCell>
                <TableCell>
                  <Box sx={{ lineHeight: 1.5 }}>
                    <Typography
                      variant="body2"
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <LocationOnIcon fontSize="small" />
                      <strong>Pickup: </strong>
                      <Tooltip title={delivery.pickup.address.address}>
                        <Typography variant="caption" noWrap>
                          {delivery.pickup.address.address}
                        </Typography>
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        mt: 0.5,
                      }}
                    >
                      <LocationOnIcon fontSize="small" />
                      <strong>Dropoff: </strong>
                      <Tooltip title={delivery.dropoff.address.address}>
                        <Typography variant="caption" noWrap>
                          {delivery.dropoff.address.address}
                        </Typography>
                      </Tooltip>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  {delivery.estimatedPickupTime ? (
                    <FormattedRelativeTime
                      value={delivery.estimatedPickupTime}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell>
                  {delivery.estimatedDropoffTime ? (
                    <FormattedRelativeTime
                      value={delivery.estimatedDropoffTime}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    <FormattedRelativeTime value={delivery.createdAt} />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledTablePaperWrapper>
  )
}
