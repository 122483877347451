import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import { FC, useState } from 'react'
import {
  DateTimePicker,
  GoogleAddressPicker,
  LocationsPicker,
} from '../../../components/Pickers'
import { useResellerLocations } from '../../../hooks/useResellerLocations'
import { useNavigate, useParams } from 'react-router-dom'
import { MoneyTextField } from '../../../components'
import { useResellerDeliveryCreateMutation } from '../../../hooks'
import { MuiTelInput } from 'mui-tel-input'
import { useIntl } from 'react-intl'
import { Camera, KeyboardReturn, Settings } from '@mui/icons-material'

export interface DeliveryCreateDialogProps extends DialogProps {}

/**
 *
 */
export const DeliveryCreateDialog: FC<DeliveryCreateDialogProps> = (props) => {
  const intl = useIntl()
  const { resellerId } = useParams()
  const navigate = useNavigate()
  console.log('resellerId', resellerId)
  const maxAllowedDate = new Date(
    new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24 * 14
  )
  const [pickupLocation, setPickupLocation] = useState<any | undefined>()
  const [dropoffLocation, setDropoffLocation] = useState<any | undefined>()
  const [deliveryMode, setDeliveryMode] = useState<'ASAP' | 'SCHEDULED'>('ASAP')
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null)
  const [customerFirstname, setCustomerFirstname] = useState('')
  const [customerLastname, setCustomerLastname] = useState('')
  const [customerContactNumber, setCustomerContactNumber] = useState('')
  const [dropoffInstructions, setDropoffInstructions] = useState('')
  const [totalValue, setTotalValue] = useState<number | null>(0.01)
  const [proofRequired, setProofRequired] = useState(false)
  const [returnPackage, setReturnPackage] = useState(false)
  const locations = useResellerLocations({ resellerId })
  const createDelivery = useResellerDeliveryCreateMutation({
    resellerId: resellerId!,
  })

  /**
   * Derived States
   */
  const isPickupLocationSelected = !!pickupLocation
  const isDropoffDetailsFilled =
    !!customerFirstname && !!customerLastname && !!customerContactNumber

  // Final state to progress to the next step
  const isCreateButtonDisabled = !pickupLocation || !isDropoffDetailsFilled
  /**
   * Handle the create delivery action
   */
  const handleCreateDelivery = () => {
    createDelivery.mutate(
      {
        locationId: pickupLocation?.id,
        accountId: pickupLocation?.accountId,
        failureAction: returnPackage ? 'RETURN' : 'DISPOSE',
        proofRequired: proofRequired,
        deliveryMode,
        package: { totalValue: totalValue ? totalValue * 100 : 1 },
        pickup: {
          address: pickupLocation?.address,
          contactName: pickupLocation?.name,
          contactPhone: pickupLocation?.phoneNumber,
          // @todo add instructions to the location record
          instructions: '',
        },
        dropoff: {
          address: {
            address: dropoffLocation.formatted_address,
            googlePlaceId: dropoffLocation.place_id,
            latitude: dropoffLocation.geometry.location.lat(),
            longitude: dropoffLocation.geometry.location.lng(),
            addressComponents: {
              city: dropoffLocation.address_components.find((c: any) =>
                c.types.includes('locality')
              )?.long_name,
              state: dropoffLocation.address_components.find((c: any) =>
                c.types.includes('administrative_area_level_1')
              )?.long_name,
              country: dropoffLocation.address_components.find((c: any) =>
                c.types.includes('country')
              )?.long_name,
              postalCode: dropoffLocation.address_components.find((c: any) =>
                c.types.includes('postal_code')
              )?.long_name,
            },
          },
          contactName: `${customerFirstname} ${customerLastname}`,
          contactPhone: customerContactNumber,
          instructions: dropoffInstructions,
        },
        estimatedPickupTime:
          deliveryMode === 'SCHEDULED'
            ? scheduledDate?.toISOString()
            : undefined,
        estimatedDropoffTime:
          deliveryMode === 'SCHEDULED'
            ? scheduledDate?.toISOString()
            : undefined,
      },
      {
        onSuccess: (result) => {
          /**
           * Redirect to the delivery details page
           */
          navigate(result.data.id)
        },
      }
    )
  }

  return (
    <Dialog fullWidth scroll="paper" {...props}>
      <DialogTitle>Create Delivery</DialogTitle>
      <DialogContent>
        {/* Form Layout with Location Picker */}
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12}>
            <Divider>Pickup Details</Divider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <LocationsPicker
                query={locations}
                onSearch={() => {}}
                multiple={false}
                placeholder=""
                label=""
                onChange={(e, value) => setPickupLocation(value)}
              />
              <FormHelperText>
                Address and Contact Information will be taken from the locaton
                record
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* Delivery Mode, ASAP vs Scheduled */}
          <Grid item xs={12}>
            <ButtonGroup fullWidth disabled={!isPickupLocationSelected}>
              <Button
                onClick={() => setDeliveryMode('ASAP')}
                variant={deliveryMode === 'ASAP' ? 'contained' : 'outlined'}
              >
                ASAP
              </Button>
              <Button
                onClick={() => setDeliveryMode('SCHEDULED')}
                variant={
                  deliveryMode === 'SCHEDULED' ? 'contained' : 'outlined'
                }
              >
                SCHEDULED
              </Button>
            </ButtonGroup>
          </Grid>
          {/* Scheduled Delivery Date */}
          {deliveryMode === 'SCHEDULED' && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DateTimePicker
                  label="Expected Delivery Date/Time"
                  maxDate={maxAllowedDate}
                  closeOnSelect={false}
                  timezone={pickupLocation?.timezone ?? 'UTC'}
                  onChange={(value) => setScheduledDate(value)}
                  value={scheduledDate}
                  ampm={false}
                  disablePast
                  disabled={!isPickupLocationSelected}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <FormHelperText>
                  {pickupLocation?.timezone ?? 'UTC'} Timezone
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider>Dropoff Details</Divider>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    size="small"
                    value={customerFirstname}
                    disabled={!isPickupLocationSelected}
                    onChange={(e) => setCustomerFirstname(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    size="small"
                    value={customerLastname}
                    disabled={!isPickupLocationSelected}
                    onChange={(e) => setCustomerLastname(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <MuiTelInput
                    label={intl.formatMessage({ id: 'label_phone_number' })}
                    placeholder="e.g. +1 123-456-7890"
                    disabled={!isPickupLocationSelected}
                    value={customerContactNumber}
                    size="small"
                    onChange={(value: any) => setCustomerContactNumber(value)}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <GoogleAddressPicker
                  onPlaceSelected={(details) => {
                    setDropoffLocation(details)
                  }}
                  disabled={!isPickupLocationSelected}
                  autoCompleteProps={{
                    debounce: 250,
                    requestOptions: {
                      types: ['address'],
                      ...(pickupLocation?.address?.latitude &&
                        pickupLocation?.address?.longitude && {
                          locationBias: {
                            lat: pickupLocation?.address?.latitude,
                            lng: pickupLocation?.address?.longitude,
                          },
                        }),
                    },
                  }}
                />
              </Grid>
              {/* Driver Instructions */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Dropoff Instructions"
                    placeholder='These notes are typically for the driver, e.g. "Ring the doorbell"'
                    size="small"
                    disabled={!isPickupLocationSelected}
                    multiline
                    rows={2}
                    value={dropoffInstructions}
                    onChange={(e) => setDropoffInstructions(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Package Details */}
          <Grid item xs={12}>
            <Divider>Package Details</Divider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <MoneyTextField
                label="Package Value"
                size="small"
                value={totalValue}
                onChange={(e) => setTotalValue(Number(e.target.value))}
                disabled={!isPickupLocationSelected}
              />
            </FormControl>
          </Grid>

          {/* List of available options */}
          <List
            sx={{ width: '100%' }}
            subheader={<ListSubheader>Options</ListSubheader>}
          >
            <ListItem disabled={!isPickupLocationSelected}>
              <ListItemIcon>
                <Camera />
              </ListItemIcon>
              <ListItemText
                primary="Delivery Proof"
                secondary="Require delivery proof from the delivery partner"
              />
              <Switch
                value={proofRequired}
                onChange={(e, checked) => setProofRequired(checked)}
              />
            </ListItem>
            <ListItem disabled={!isPickupLocationSelected}>
              <ListItemIcon>
                <KeyboardReturn />
              </ListItemIcon>
              <ListItemText
                primary="Return Package"
                secondary="Request the package to be returned if the delivery fails, this may incur additional charges"
              />
              <Switch
                value={returnPackage}
                onChange={(e, checked) => setReturnPackage(checked)}
              />
            </ListItem>
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={createDelivery.isLoading}
          onClick={(e) => props.onClose && props.onClose(e, 'backdropClick')}
        >
          Cancel
        </Button>
        <Button
          color="success"
          disabled={isCreateButtonDisabled || createDelivery.isLoading}
          onClick={handleCreateDelivery}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
