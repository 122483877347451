import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppLoader, GenericCard } from '../../../components'
import { CenteredBox } from '../../../components/CenteredBox'
import { useResellerAccountMetrics } from '../../../hooks/useResellerAccountMetrics'
import { RangeSelector } from '../../../components/RangeSelector'
import { FormattedNumber, useIntl } from 'react-intl'
import { FormattedMoney } from '../../../components/FormattedMoney'
import { Chart } from 'react-chartjs-2'

export const ResellerAccountDashboard: FC<{
  currencyValue: any
}> = (props: any) => {
  const { currencyValue } = props
  const { resellerId, accountId } = useParams()
  const [range, setRange] = useState<string>('1m')
  const metrics = useResellerAccountMetrics({
    range,
    resellerId: resellerId!,
    accountId: accountId!,
  })

  if (metrics.isLoading) {
    return <AppLoader />
  }

  if (metrics.isError || metrics.isSuccess === false) {
    return (
      <CenteredBox>
        <Typography>
          Metrics are not currently available, please try again later.
        </Typography>
      </CenteredBox>
    )
  }
  const {
    totalRevenue,
    totalOrders,
    totalLocationsWithOrders,
    averageOrderValue,
    filteredOrders,
  } = metrics.data.aggregations
  const intl = useIntl()

  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={2}>
        {/* Total Orders */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Total Orders"
            subtitle="The total number of orders for your reseller account."
            helpText={intl.formatMessage({
              id: 'dashboard_orders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber value={totalOrders?.value ?? 0} />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Revenue Generated */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Total Revenue"
            subtitle="The total revenue for your reseller account."
            helpText={intl.formatMessage({
              id: 'dashboard_totalRevenue_helpText',
            })}
          >
            <Typography variant="h4" component="span">
              <FormattedMoney
                currency={currencyValue}
                value={totalRevenue?.value ? totalRevenue.value / 100 : 0}
              />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Average Order Value */}
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title="Average Order Value"
            subtitle="The average order value for your reseller account."
            helpText={intl.formatMessage({
              id: 'dashboard_reseller_avgOrderValue_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  averageOrderValue?.value ? averageOrderValue.value / 100 : 0
                }
                style="currency"
                currency={currencyValue}
                maximumFractionDigits={2}
              />
            </Typography>
          </GenericCard>
        </Grid>
      </Grid>
      {/* Range Selector */}
      <Box sx={{ flexGrow: 1, pt: 3 }}>
        <Box sx={{ flexGrow: 1, float: 'right' }}>
          <RangeSelector
            options={['6h', '1d', '3d', '1w', '1m', '3m', '6m']}
            selected={range}
            onChange={(newValue) => setRange(newValue)}
          />
        </Box>
      </Box>
      {/* Ranged Stats / Charts */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex' }}>
          <GenericCard
            title="Orders"
            subtitle="The total number of orders created within time period."
            helpText={intl.formatMessage({
              id: 'dashboard_totalOrders_helpText',
            })}
          >
            <Typography variant="h4">{filteredOrders?.doc_count}</Typography>
          </GenericCard>
        </Grid>
        {/* Total Locations */}
        <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex' }}>
          <GenericCard
            title="Active Locations"
            subtitle="The total number of locations that have atleast one order"
            helpText={intl.formatMessage({
              id: 'dashboard_newLocations_helpText',
            })}
          >
            <Typography variant="h4">
              {totalLocationsWithOrders?.value}
            </Typography>
          </GenericCard>
        </Grid>
        {/* Order volume over time */}
        <Grid item xs={12} md={12} lg={12}>
          <GenericCard
            title="Order volume over time"
            subtitle="This chart shows the order volume within the selected time period."
            helpText={intl.formatMessage({
              id: 'dashboard_volumeOverTimeChart_helpText',
            })}
          >
            <Chart
              style={{ maxHeight: '300px' }}
              options={{ scales: { x: { type: 'timeseries' } } }}
              data={{
                datasets: [
                  {
                    label: 'Orders',
                    data: filteredOrders.orderVolumeHistogram.buckets.map(
                      (bucket: any) => ({ x: bucket.key, y: bucket.doc_count })
                    ),
                  },
                ],
              }}
              type="line"
            />
          </GenericCard>
        </Grid>
        {/* Top Locations */}
        <Grid item xs={12} md={12} lg={12}>
          <GenericCard
            title="Top Locations"
            subtitle="This table shows the top locations by revenue."
            helpText={intl.formatMessage({
              id: 'dashboard_topLocation_helpText',
            })}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell>Orders</TableCell>
                  <TableCell>Revenue</TableCell>
                  <TableCell>Average Order Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders?.topLocations?.buckets.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography>
                        No data available for the selected range.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {filteredOrders?.topLocations?.buckets.map((bucket: any) => (
                  <TableRow key={bucket.key}>
                    <TableCell>
                      {bucket.details.hits.hits[0]._source.locationName}
                    </TableCell>
                    <TableCell>
                      <FormattedNumber value={bucket.totalOrders.value} />
                    </TableCell>
                    <TableCell>
                      <FormattedMoney
                        currency={currencyValue}
                        value={bucket.totalRevenue.value / 100}
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMoney
                        currency={currencyValue}
                        value={bucket.averageOrderValue.value / 100}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GenericCard>
        </Grid>
      </Grid>
    </Box>
  )
}
