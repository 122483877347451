import { FC } from 'react'
import { UseQueryResult } from 'react-query'
import { Map, Marker, AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import { CircularProgress, Alert, Box, Avatar } from '@mui/material'
import { CarRental } from '@mui/icons-material'

type DeliveryMapProps = {
  delivery: UseQueryResult<any, Error>
}

export const DeliveryMap: FC<DeliveryMapProps> = ({ delivery }) => {
  const { data, isLoading, error } = delivery

  if (isLoading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  if (!data) {
    return <Alert severity="info">No delivery data available.</Alert>
  }

  /**
   * Extract pickup, dropoff and driver information
   */
  const { pickup, dropoff, driver } = data.data

  /**
   * Calculcate the centroid of the pickup and dropoff locations
   */
  const midpoint = getGeographicMidpoint(
    pickup.address.latitude,
    pickup.address.longitude,
    dropoff.address.latitude,
    dropoff.address.longitude
  )

  return (
    <Box mt={3}>
      <Map
        // could store preffered mapId in the reseller settings for theming
        mapId={`delivery-map-${data.data.id}`}
        style={{ height: '100vh' }}
        defaultCenter={midpoint}
        defaultZoom={13}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      >
        {/* Pickup Location */}
        {driver && driver.latitude && driver.longitude && (
          <AdvancedMarker
            key="driver"
            position={{
              lat: driver.latitude,
              lng: driver.longitude,
            }}
          >
            <Avatar variant="circular" sx={{ backgroundColor: 'primary.main' }}>
              P
            </Avatar>
          </AdvancedMarker>
        )}

        {/* Dropoff  */}
        <AdvancedMarker
          position={{
            lat: dropoff.address.latitude,
            lng: dropoff.address.longitude,
          }}
        >
          <Avatar variant="circular" sx={{ backgroundColor: 'primary.main' }}>
            D
          </Avatar>
        </AdvancedMarker>

        {/* Pickup */}
        <AdvancedMarker
          position={{
            lat: pickup.address.latitude,
            lng: pickup.address.longitude,
          }}
        >
          <Avatar variant="circular" sx={{ backgroundColor: 'primary.main' }}>
            D
          </Avatar>
        </AdvancedMarker>
      </Map>
    </Box>
  )
}

function getGeographicMidpoint(
  pickupLat: number,
  pickupLon: number,
  dropoffLat: number,
  dropoffLon: number
) {
  const toRadians = (degrees: number) => degrees * (Math.PI / 180)
  const toDegrees = (radians: number) => radians * (180 / Math.PI)

  // Convert lat/lon to radians
  const lat1 = toRadians(pickupLat)
  const lon1 = toRadians(pickupLon)
  const lat2 = toRadians(dropoffLat)
  const lon2 = toRadians(dropoffLon)

  // Cartesian coordinates
  const x1 = Math.cos(lat1) * Math.cos(lon1)
  const y1 = Math.cos(lat1) * Math.sin(lon1)
  const z1 = Math.sin(lat1)

  const x2 = Math.cos(lat2) * Math.cos(lon2)
  const y2 = Math.cos(lat2) * Math.sin(lon2)
  const z2 = Math.sin(lat2)

  // Average the Cartesian coordinates
  const xMid = (x1 + x2) / 2
  const yMid = (y1 + y2) / 2
  const zMid = (z1 + z2) / 2

  // Convert back to latitude and longitude
  const midLat = Math.atan2(zMid, Math.sqrt(xMid * xMid + yMid * yMid))
  const midLon = Math.atan2(yMid, xMid)

  return {
    lat: toDegrees(midLat),
    lng: toDegrees(midLon),
  }
}
