import { ChangeEvent, FC, useState } from 'react'
import { Button, Grid, InputAdornment } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { AppLoader, CommonTextField } from '../../components'
import {
  LocationsPicker,
  OrderFulfillmentTypePicker,
  OrderStatusPicker,
} from '../../components/Pickers'
import { UseInfiniteQueryResult } from 'react-query'

export interface OrderFilterProps {
  onApply: (data: any) => void
  onReset: () => void
  queryParams: any
  locations: any
  loading?: boolean

  /**
   * V2
   */
  locationsQuery: UseInfiniteQueryResult<any, any>
}

export const OrderFilter: FC<OrderFilterProps> = (props) => {
  const { locations, locationsQuery, queryParams, onApply, loading } = props
  const intl = useIntl()
  /**
   * We create local state of the supported filters so we can power the UI,
   * once the user clicks apply we will inform the parent component of the new filters,
   * which will be persisted in the URL
   */
  const [filters, setFilters] = useState({
    query: queryParams.query,
    status: queryParams.status,
    fulfillmentType: queryParams.fulfillmentType,
    locationId: queryParams.locationId,
  })

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value || undefined!
    setFilters({ ...filters, query })
  }

  const handleChangeStatus = (event: any, value: any) => {
    const status = value?.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, status })
    onApply({ ...filters, status }) // Auto Apply the filters when the status changes
  }
  const handleChangeType = (event: any, value: any) => {
    const fulfillmentType = value?.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, fulfillmentType })
    onApply({ ...filters, fulfillmentType }) // Auto Apply the filters when the type changes
  }
  const handleChangeLocation = (event: any, value: any[]) => {
    const locationId =
      value?.length > 0
        ? value.map((location) => location.id).join(',')
        : undefined
    setFilters({ ...filters, locationId })
    onApply({ ...filters, locationId }) // Auto Apply the filters when the location changes
  }

  const selectedLocations = filters.locationId?.split(',') || []

  const selected = locations?.filter((location: any) =>
    selectedLocations.includes(location.id)
  )
  if (loading) return <AppLoader />
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3} lg={3}>
        <CommonTextField
          id="outlined-basic"
          size="small"
          label={intl.formatMessage({ id: 'action_search' })}
          onChange={handleChangeText}
          value={filters.query || ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={2.6} lg={2.6}>
        <OrderStatusPicker
          multiple
          onChange={handleChangeStatus}
          value={filters.status?.split(',') ?? []}
          disableCloseOnSelect
          limitTags={1}
        />
      </Grid>
      <Grid item xs={12} md={2.6} lg={2.6}>
        <OrderFulfillmentTypePicker
          multiple
          onChange={handleChangeType}
          value={filters.fulfillmentType?.split(',') ?? []}
          disableCloseOnSelect
          limitTags={1}
        />
      </Grid>
      <Grid item xs={12} md={2.6} lg={2.6}>
        <LocationsPicker
          query={locationsQuery}
          value={selected}
          onChange={handleChangeLocation}
          multiple
          disableCloseOnSelect
          limitTags={1}
          onSearch={(query) => console.log('filter', query)}
        />
      </Grid>
      <Grid item>
        <Button
          size="medium"
          variant="outlined"
          onClick={() => onApply(filters)}
        >
          {intl.formatMessage({ id: 'action_apply' })}
        </Button>
      </Grid>
    </Grid>
  )
}
