import { DetailedHTMLProps, FC, ReactNode } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import {
  AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  CircularProgress,
  TextField,
} from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { BasePicker, BasePickerProps } from '../BasePicker'

/**
 * Query picker props
 */
export interface QueryPickerProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    BasePickerProps<T, Multiple, DisableClearable, FreeSolo>,
    'options' | 'renderOption' | 'renderInput'
  > {
  /**
   * Infinite query result from react-query
   */
  query: UseInfiniteQueryResult<any, any>

  /**
   * Render input props
   */
  label?: string

  /**
   * Callback to notify parent when the search query changes
   */
  onSearch: (query: string) => void

  /**
   * Field for extracting labels from the options data
   */
  getOptionLabel?: (option: any) => string

  /**
   * Render option component
   */
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode

  /**
   * Render option component
   */
  renderOption?: (
    renderProps: any,
    option: any,
    { selected }: any
  ) => JSX.Element
}

/**
 * Provides a generic query picker component that extends the Autocomplete component,
 * this component is used to search and select a query, using a reqct query infinite query result
 */
export const QueryPicker: FC<
  QueryPickerProps<any, boolean, boolean, boolean>
> = (props) => {
  /**
   * Based on all the data available within the infinite query, produce a flat list of entities to be used as options
   */
  const options = props.query.data?.pages.flatMap((page) => page.data) ?? []

  /**
   * Helper function used to extract the displayable label from the input option
   */
  const getOptionLabel = (option: any) => {
    return props.getOptionLabel
      ? props.getOptionLabel(option)
      : option.name || ''
  }
  /**
   * Function that monitors the scroll position of the autocomplete component and triggers the fetchMore function
   */
  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = event.target as HTMLDivElement
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      props.query.hasNextPage &&
      !props.query.isFetchingNextPage
    ) {
      if (props.query.isFetching) return
      void props.query.fetchNextPage()
    }
  }
  /**
   * Return the autocomplete component with the props passed
   */
  return (
    <BasePicker
      size="small"
      getOptionLabel={getOptionLabel}
      loading={props.query.isFetching}
      ListboxProps={{ onScroll: handleScroll, ...props.ListboxProps }}
      renderOption={(rProps, option, { selected }) => (
        <QueryPickerOption
          {...rProps}
          key={option.id}
          selected={selected}
          label={getOptionLabel(option)}
        />
      )}
      // renderTags={(options, getTagProps) => {
      //   return options.map((option, index) => (
      //     <Chip
      //       size="small"
      //       label={getOptionLabel(option)}
      //       {...getTagProps({ index })}
      //     />
      //   ))
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.query.isFetching && (
                  <CircularProgress color="inherit" size={16} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
      options={options}
    />
  )
}

export interface QueryPickerOptionProps
  extends DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  selected: boolean
  label: ReactNode
}

export const QueryPickerOption: FC<QueryPickerOptionProps> = ({
  selected,
  label,
  ...props
}) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <Checkbox
        color="info"
        size="small"
        icon={<CheckBoxOutlineBlank fontSize="small" />}
        checkedIcon={<CheckBox fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {label}
    </li>
  )
}
