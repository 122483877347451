import {
  Box,
  Button,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { AppLoader } from '../AppLoader'
import { useIntl } from 'react-intl'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { ClickableIcon } from '../ClickableIcon'
import { CreateButtonFlexEnd } from '../CreateButtonFlexEnd'
import { StyledTablePaperWrapper } from '../MUIStyledComponents'

export interface Column {
  id: string
  label: string
  minWidth?: number
  align?: 'right'
  component?: (value: any) => JSX.Element
}

export interface GenericTableProps {
  query: any
  columns: readonly Column[]
  noDataMessage: string
  enableDelete: boolean
  handleDelete?: (account: any) => void
  tableType: string
  filters?: any
}
export const GenericTable: FC<{
  query: any
  columns: readonly Column[]
  noDataMessage: string
  enableDelete: boolean
  handleDelete?: (account: any) => void
  tableType: string
  filters?: any
}> = (props: GenericTableProps) => {
  const {
    query,
    columns,
    noDataMessage,
    enableDelete,
    handleDelete,
    tableType,
    filters,
  } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const records = query.data?.pages.map((value: any) => value.data).flat()
  // Locale Messages
  const labelorderDetails = intl.formatMessage({ id: 'label_order_details' })
  const actionLoadMore = intl.formatMessage({ id: 'action_load_more' })

  if (query.isLoading) {
    return <AppLoader />
  }

  if (query.isError) {
    return (
      <Typography component="span">
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  return (
    <>
      <CreateButtonFlexEnd
        handleClick={() => navigate(`new`)}
        buttonType={tableType}
      />
      {query?.isSuccess && records?.length ? (
        <StyledTablePaperWrapper>
          <TableContainer sx={{ maxHeight: '700px' }}>
            <Grid container spacing={2}>
              {!isTablet ? (
                <>
                  {records?.map((row: any) => (
                    <Grid item xs={12} key={row.id}>
                      <Paper>
                        <CardContent>
                          <TableContainer>
                            <Table aria-label={labelorderDetails} size="small">
                              <TableBody>
                                {columns.map((column) => {
                                  const value = row[column.id]
                                  return (
                                    <TableRow
                                      key={column.id}
                                      onClick={() =>
                                        navigate(
                                          `${row.id}?query=${
                                            filters?.query ? filters.query : ''
                                          }`
                                        )
                                      }
                                    >
                                      <TableCell
                                        scope="row"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {column.label}
                                      </TableCell>
                                      <TableCell align="left">
                                        {column.component
                                          ? column.component(row)
                                          : value}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Paper>
                    </Grid>
                  ))}
                  {query.hasNextPage && (
                    <Box display="flex" justifyContent="center" my={2}>
                      <Button
                        onClick={() => query.fetchNextPage()}
                        disabled={query.isFetching}
                      >
                        {actionLoadMore}
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <Grid item xs={12}>
                  <Paper>
                    <TableContainer sx={{ maxHeight: 625, overflow: 'auto' }}>
                      <Table stickyHeader aria-label={labelorderDetails}>
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: 'bold',
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {records?.map((row: any) => (
                            <TableRow
                              key={row.id}
                              hover
                              sx={{
                                cursor: 'pointer',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                              }}
                              onClick={() =>
                                tableType === 'account'
                                  ? navigate(
                                      `${row.id}?query=${
                                        filters?.query ? filters.query : ''
                                      }`
                                    )
                                  : // : tableType === 'location'
                                    // ? navigate(`${row.id}/apps?query=${filters?.query} ? filters.query : ''`)
                                    navigate(
                                      `${row.id}/edit?query=${
                                        filters?.query ? filters.query : ''
                                      }`
                                    )
                              }
                            >
                              {columns.map((column) => {
                                const value = row[column.id]
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.component
                                      ? column.component(row)
                                      : value}
                                  </TableCell>
                                )
                              })}
                              <TableCell onClick={(e) => e.stopPropagation()}>
                                <Box sx={{ display: 'flex' }}>
                                  <ClickableIcon
                                    handleNavigate={() =>
                                      tableType === 'account'
                                        ? navigate(
                                            `${
                                              row.id
                                            }/edit?from=account&query=${
                                              filters?.query
                                                ? filters.query
                                                : ''
                                            }`
                                          )
                                        : navigate(
                                            `${row.id}/edit?query=${
                                              filters?.query
                                                ? filters.query
                                                : ''
                                            }`
                                          )
                                    }
                                    children={<EditOutlined />}
                                  />
                                  {enableDelete ? (
                                    <ClickableIcon
                                      handleNavigate={() =>
                                        handleDelete && handleDelete(row)
                                      }
                                      children={<DeleteOutline />}
                                    />
                                  ) : null}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {query.hasNextPage && (
                      <Box display="flex" justifyContent="center" my={2}>
                        <Button
                          onClick={() => query.fetchNextPage()}
                          disabled={query.isFetching}
                        >
                          {actionLoadMore}
                        </Button>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              )}
            </Grid>
          </TableContainer>
        </StyledTablePaperWrapper>
      ) : (
        <Paper sx={{ marginTop: 5 }}>
          <CardContent>
            <Typography
              component="span"
              align="center"
              style={{ marginTop: 10 }}
            >
              {noDataMessage}
            </Typography>
          </CardContent>
        </Paper>
      )}
    </>
  )
}
