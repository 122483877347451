import 'chart.js/auto'
import 'chartjs-adapter-moment'
import './styles/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClientProvider } from 'react-query'
import { lightTheme } from './theme/poshub'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { DateFnsProvider } from 'react-hook-form-mui/dist/date-fns'
import { Routes } from './pages'
import { ToastContainer } from 'react-toastify'
import { IntlProvider } from './components/IntlProvider'
import { queryClient } from './queryClient'
import { FC } from 'react'
import * as defaultMessages from './messages/en-GB.json'
import { APIProvider as MapsProvider } from '@vis.gl/react-google-maps'

/**
 *
 */
export interface AppProps {}

/**
 * Application
 */
export const App: FC<AppProps> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <ToastContainer />
      <IntlProvider
        defaultLocale="en-GB"
        defaultMessages={defaultMessages}
        availableLanguages={['en-GB', 'en-US']}
      >
        <DateFnsProvider>
          <MapsProvider apiKey={GOOGLE_MAPS_API_KEY}>
            <BrowserRouter>
              <ThemeProvider theme={lightTheme}>
                <Routes />
              </ThemeProvider>
            </BrowserRouter>
          </MapsProvider>
        </DateFnsProvider>
      </IntlProvider>
    </QueryClientProvider>
  )
}
