import { Chip } from '@mui/material'
import { FC } from 'react'

export const DeliveryStatusLabel: FC<{
  status: string
}> = (props) => {
  const statusColorMap: Record<
    string,
    'default' | 'primary' | 'success' | 'warning' | 'error'
  > = {
    CREATED: 'default',
    IN_PROGRESS: 'success',
    COMPLETED: 'primary',
    CANCELLED: 'error',
  }

  const readableStatusMap: Record<string, string> = {
    CREATED: 'Created',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  }

  const getStatusColor = (status: string) => statusColorMap[status] || 'default'
  const readableStatus = readableStatusMap[props.status] || props.status

  return (
    <Chip
      label={readableStatus}
      color={getStatusColor(props.status)}
      size="small"
      sx={{
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
      }}
    />
  )
}
