import { OrdersTable } from '../../../components/OrdersTable'
import { OrderFilter } from '../../../pages/Orders/OrderFilterDrawer'
import { useNavigate, useParams } from 'react-router-dom'
import { useResellerOrders } from '../../../hooks'
import { useQueryString } from '../../../hooks/useQueryString'
import { useResellerLocations } from '../../../hooks/useResellerLocations'

export function ResellerOrders() {
  let [queryParams, setSearchParams] = useQueryString(
    window.location,
    useNavigate()
  )
  const { resellerId } = useParams()

  const locations = useResellerLocations(
    { resellerId: resellerId! },
    {
      onSuccess(data: any) {
        const d = data.pages.length
        if (data.pages[d - 1].hasNextPage) {
          void locations.fetchNextPage()
        }
      },
    }
  )
  const locationsData = locations.data?.pages
    .map((page: any) => page.data)
    .flat()

  return (
    <>
      <OrderFilter
        onApply={(data: any) => setSearchParams(data)}
        onReset={() => setSearchParams({})}
        queryParams={queryParams}
        locations={locationsData}
        loading={locations.isLoading}
        // v2
        locationsQuery={locations}
      />
      <OrdersPage queryParams={queryParams} />
    </>
  )
}

const OrdersPage = ({ queryParams }: { queryParams: any }) => {
  const { resellerId } = useParams()
  const filters = { ...queryParams }
  /**
   * Create the data query to fetch the orders
   */
  const orders = useResellerOrders(
    { filters, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  return <OrdersTable orders={orders} />
}
