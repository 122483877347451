import { Box, Typography } from '@mui/material'
import { FC, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

interface PageNavigationViewProps {
  navigationPages: { label: string; path: string }[]
}
export const PageNavigationView: FC<PageNavigationViewProps> = (props) => {
  const navigate = useNavigate()
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {props.navigationPages?.map((value: any, index: any) => {
          return (
            <Fragment key={index}>
              <Typography
                variant="caption"
                component="p"
                sx={{ mx: 1, cursor: 'pointer', color: 'info.main' }}
                onClick={() => navigate(`${value.path}`)}
              >
                {value.label}
              </Typography>
              {index !== props.navigationPages.length - 1 && (
                <Typography variant="caption" component="p">
                  {'>>'}
                </Typography>
              )}
            </Fragment>
          )
        })}
      </Box>
    </Box>
  )
}
