import { FC } from 'react'

type LogoValue =
  | 'UBEREATS'
  | 'DELIVEROO'
  | 'DOORDASH'
  | 'CAVIAR'
  | 'SEAMLESS'
  | 'DIRECT'
  | 'GROUPON'
  | 'YELP'
  | 'EAT24'
  | 'TAPINGO'
  | 'UNKNOWN'
  | 'FOODHUB'
  | 'GRUBHUB'
  | 'JUSTEAT'

interface LogoProps {
  value: string
}

const logoUrls: Record<LogoValue, string> = {
  UBEREATS:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/ubereats.webp',
  DELIVEROO:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/deliveroo.webp',
  DOORDASH:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/doordash.webp',
  CAVIAR:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/doordash.webp',
  FOODHUB:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/foodhub.webp',
  GRUBHUB:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  SEAMLESS:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  DIRECT:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  GROUPON:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  YELP: 'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  EAT24:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  TAPINGO:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  UNKNOWN:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/grubhub.webp',
  JUSTEAT:
    'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/justeat.webp',
}

const defaultLogoUrl =
  'https://falcon-sit-tpi-logos.s3.us-east-1.amazonaws.com/MarketPlace/no_image.webp'

const Logo: FC<LogoProps> = ({ value }) => {
  if (value in logoUrls) {
    const logoUrl = logoUrls[value as LogoValue]
    return (
      <img
        style={{ width: 40, height: 40, borderRadius: '4px' }}
        src={logoUrl}
        alt={`${value} Logo`}
      />
    )
  }

  return (
    <img
      style={{ width: 40, height: 35, borderRadius: '6px' }}
      src={defaultLogoUrl}
      alt="Default Logo"
    />
  )
}

export default Logo
