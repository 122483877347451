import { FC, useEffect, useState } from 'react'
import {
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
  MenuItem,
} from '@mui/material'
import PlacesAutocomplete from 'react-places-autocomplete'
import ClearIcon from '@mui/icons-material/Clear'
import { CommonTextField } from '../CommonTextField'

interface AutoCompleteAddressProps {
  disableEnable?: boolean
  formattedAddress?: string
  placeholderName: string
  handleChangeAddress: (value: string) => void
  removeAddress: () => void
}

export const AutoCompleteAddress: FC<AutoCompleteAddressProps> = ({
  disableEnable,
  formattedAddress,
  placeholderName,
  handleChangeAddress,
  removeAddress,
}) => {
  const [address, setAddress] = useState(formattedAddress as string | undefined)
  useEffect(() => {
    setAddress(formattedAddress)
  }, [formattedAddress])

  const handleSelect = (value: string) => {
    setAddress(value)
    handleChangeAddress(value)
  }

  const handleClear = () => {
    setAddress('')
    removeAddress()
  }
  useEffect(() => {
    if (address === '') {
      removeAddress()
    }
  }, [address])

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box>
          <CommonTextField
            {...getInputProps({ placeholder: placeholderName })}
            value={address}
            fullWidth
            disabled={disableEnable === true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {address && !disableEnable && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            label={placeholderName}
          />
          <>
            {loading ? <CircularProgress color="primary" /> : null}
            {suggestions.map((suggestion) => {
              const { description } = suggestion
              const style = {
                backgroundColor: suggestion.active ? '#e8e8e8' : '#ffffff',
              }
              return (
                <MenuItem
                  {...getSuggestionItemProps(suggestion, { style })}
                  key={description}
                >
                  {description}
                </MenuItem>
              )
            })}
          </>
        </Box>
      )}
    </PlacesAutocomplete>
  )
}
