import {
  createTheme,
  ThemeOptions,
  lighten,
  darken,
} from '@mui/material/styles'
import { back } from 'nock'
import { font } from 'pdfkit'

/**
 * Global Default Constants
 */
const fontFamily = 'Lato, sans-serif'
const htmlFontSize = 16

/**
 * Global Theme Colors
 */
const primaryColor = '#D82927'
const secondaryColor = '#888888'
const successColor = '#5bae75'
const errorColor = '#d82927'
const infoColor = '#1976d2'

/**
 * Default variants
 */
const defaultDensity = 'dense' // 'regular' | 'dense'

/**
 * Computed Properties
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const isDense = defaultDensity === 'dense'

/**
 * Import Fonts -
 *
 * background changed to inherit for MuiTextField
 */
export const lightTheme: ThemeOptions = createTheme({
  typography: {
    fontFamily,
    htmlFontSize,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          '& .MuiInputBase-root': { backgroundColor: '#fff' },
          '& .MuiFormHelperText-root': {
            backgroundColor: 'inherit',
            margin: 0,
          },
          '& .MuiOutlinedInput-root': { paddingLeft: 4 },
          '& .MuiInputBase-multiline': { paddingLeft: 12 },
        },
      },
    },
    MuiToolbar: { defaultProps: { variant: defaultDensity } },
    MuiAppBar: { defaultProps: { variant: 'outlined' } },
    MuiPaper: { defaultProps: { variant: 'outlined' } },
    MuiDrawer: {
      defaultProps: {},
      styleOverrides: { paper: {} },
    },
    MuiAccordion: { styleOverrides: {} },
    MuiList: { defaultProps: { dense: isDense } },
    MuiListItem: {
      defaultProps: { dense: isDense },
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0, // Ensure the left alignment is consistent
              top: 0,
              bottom: 0,
              width: '4px',
              backgroundColor: primaryColor,
            },
            color: primaryColor,
            '& .MuiListItemIcon-root': { color: primaryColor },
          },
          '&.Mui-nested': {
            paddingLeft: '16px', // Adjust this value to align nested items with the parent
            '&.Mui-selected::before': {
              left: '0px', // Aligns the nested bar with the parent bar
            },
          },
          // disabled state
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
            '& .MuiListItemIcon-root': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
        },
      },
    },
    MuiIconButton: { defaultProps: { size: 'small' } },
    MuiTableContainer: {
      defaultProps: {},
      styleOverrides: { root: { borderRadius: '8px' } },
    },
    MuiTable: { defaultProps: { size: 'small' } },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              fontWeight: 'bold',

              borderBottom: '1px solid rgba(224, 224, 224, 1)',
            },
          },
        },
      },
    },
    MuiTableRow: {
      defaultProps: { hover: true },
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&:last-child': {
            '& .MuiTableCell-root': { borderBottom: 'none' },
          },
        },
      },
    },
    MuiTableCell: {
      defaultProps: { size: 'small' },
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-root': { cursor: 'pointer' },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            borderRadius: '8px',
          },
          '& .MuiDialogTitle-root': {},
          '& .MuiDialogContent-root': { marginBottom: '5px', border: 'none' },
          '& .MuiDialogActions-root': { padding: '10px', margin: 0 },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: { variant: 'subtitle1' },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&.MuiDivider-textAlignLeft::before': {
            width: '2% !important',
          },
          '&.MuiDivider-textAlignLeft::after': {
            width: '98% !important',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained', size: 'small', color: 'success' },
      styleOverrides: { root: { borderRadius: 8 } },
    },
    MuiLink: {
      defaultProps: { color: infoColor, fontSize: 'inherit' },
      styleOverrides: {
        root: {
          padding: 8,
          '&:hover': { cursor: 'pointer' },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
            {
              paddingRight: '50px',
            },
        },
      },
    },
    MuiFormControl: {
      defaultProps: { variant: 'standard' },
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root.MuiFormLabel-filled': {
            backgroundColor: 'transparent',
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: '#1976d2',
          },
          '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //   borderColor: '#f7f7f7',
            // },
            // '&:hover fieldset': {
            //   borderColor: '#f7f7f7',
            // },
            // '&.Mui-focused fieldset': {
            //   borderColor: '#1976d2',
            // },
            // '&.Mui-disabled fieldset': {
            //   borderColor: '#f7f7f7',
            // },
          },
          '& .MuiOutlinedInput-root .MuiInputAdornment-root': {
            padding: 8,
            margin: 0,
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiInputAdornment-root .MuiTypography-root':
            {
              color: 'rgba(0, 0, 0, 0.38)',
            },
          '& .MuiInputBase-adornedEnd': {
            paddingRight: 0,
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1976d2', // Blue
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#f7f7f7',
      paper: '#ffffff',
    },
    primary: {
      main: primaryColor,
      light: lighten(primaryColor, 0.1),
      dark: darken(primaryColor, 0.4),
    },
    secondary: {
      main: secondaryColor,
      light: lighten(secondaryColor, 0.1),
      dark: darken(secondaryColor, 0.4),
    },
    error: {
      // main: '#d82927',
      main: errorColor,
      light: lighten(errorColor, 0.1),
      dark: darken(errorColor, 0.4),
    },
    info: {
      main: infoColor,
      // light: 'rgba(71, 143, 236, 0.08)',
      light: lighten(infoColor, 0.1),
    },
    success: {
      main: successColor,
      light: lighten(successColor, 0.1),
      dark: darken(successColor, 0.4),
    },
  },
})
