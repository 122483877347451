import { CheckCircleOutline, ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Avatar,
  Chip,
  Collapse,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  Typography,
} from '@mui/material'
import { FormattedRelativeTime } from '../../../components/FormattedRelativeTime'
import { FormattedMoney } from '../../../components/FormattedMoney'
import { Schema } from '../../../../../../pos-hub/runtime/types'
import { FC, useState } from 'react'
import { UseQueryResult } from 'react-query'

export type DeliveryDispatched = Schema<'DeliveryEntity'>['dispatched'][0]

/**
 * Delivery Quote List Props
 */
export interface DeliveryDispatchedListProps extends MenuListProps {
  delivery: UseQueryResult<{ data: Schema<'DeliveryEntity'> }>
  disabled?: boolean
}

/**
 *
 */
export const DeliveryDispatchedList: FC<DeliveryDispatchedListProps> = (
  props
) => {
  if (props.delivery.isLoading || props.delivery.isError) {
    return null
  }

  return (
    <MenuList {...props}>
      {props.delivery.data?.data.dispatched.map((dispatched) => (
        <DeliveryDispatchedListItem
          key={dispatched.dispatchedId}
          dispatched={dispatched}
          disabled={props.disabled}
        />
      ))}
    </MenuList>
  )
}

/**
 *
 */
export interface DeliveryDispatchedListItemProps extends MenuItemProps {
  dispatched: DeliveryDispatched
  disabled?: boolean
}

export const DeliveryDispatchedListItem: FC<DeliveryDispatchedListItemProps> = (
  props
) => {
  const { dispatched, disabled, ...otherProps } = props
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <MenuItem
        disabled={props.disabled}
        {...otherProps}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ListItemIcon>
          <CheckCircleOutline fontSize="small" color="success" />
        </ListItemIcon>
        <ListItemText
          secondary={
            <>
              Dispatched{' '}
              {dispatched.dispatchedAt && (
                <FormattedRelativeTime value={dispatched.dispatchedAt} />
              )}
            </>
          }
        >
          {dispatched.applicationName}{' '}
          <Chip label={dispatched.driverStatus} size="small" />
        </ListItemText>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <FormattedMoney
            value={dispatched.price / 100}
            currency="USD"
            decimalValue
          />
        </Typography>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={isExpanded}>
        <MenuList>
          <MenuItem>
            <ListItemAvatar>
              <Avatar variant="rounded" sx={{ width: 24, height: 24 }}></Avatar>
            </ListItemAvatar>
            {dispatched.driverName && (
              <ListItemText
                primary={dispatched.driverName}
                secondary={dispatched.driverPhoneNumber}
              />
            )}
          </MenuItem>
        </MenuList>
      </Collapse>
    </>
  )
}
