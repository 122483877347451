import { useParams } from 'react-router-dom'
import { FC, useState } from 'react'
import {
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Box,
  useMediaQuery,
  Link as MuiLink,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
} from '@mui/material'
import {
  LocationOn,
  Person,
  DirectionsCar,
  AccessTime,
  Cancel,
  Refresh,
} from '@mui/icons-material'
import {
  useResellerDeliveryById,
  useResellerDeliveryDispatchMutation,
  useResellerDeliveryRefreshMutation,
} from '../../../hooks'
import { DeliveryMap } from './DeliveryMap'
import { DeliveryQuoteList } from './DeliveryQuoteList'
import { DeliveryDispatchedList } from './DeliveryDispatchedList'

export const ResellerDeliveryById: FC = () => {
  const { resellerId, deliveryId } = useParams()
  const [selectedQuoteIdForDispatch, setSelectedQuoteIdForDispatch] = useState<
    string | undefined
  >(undefined)
  const delivery = useResellerDeliveryById(
    {
      resellerId: resellerId!,
      deliveryId: deliveryId!,
    },
    {
      enabled: !!resellerId && !!deliveryId,
      refetchInterval: 30000,
    }
  )
  const dispatch = useResellerDeliveryDispatchMutation({
    resellerId: resellerId!,
    deliveryId: deliveryId!,
  })
  const refresh = useResellerDeliveryRefreshMutation({
    resellerId: resellerId!,
    deliveryId: deliveryId!,
  })

  /**
   * Handle Dialog Confirm
   */
  const handleConfirm = () => {
    dispatch.mutate(
      { quoteId: selectedQuoteIdForDispatch },
      {
        onSuccess: () => {
          setSelectedQuoteIdForDispatch(undefined)
          void delivery.refetch()
        },
      }
    )
  }
  const isMobile = useMediaQuery('(max-width: 900px)')

  if (delivery.isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (delivery.error) {
    return <Alert severity="error">{delivery.error.message}</Alert>
  }

  const openQuoteDialog = (quote: any) => {
    setSelectedQuoteIdForDispatch(quote.quoteId)
  }

  const onRefreshQuotes = () => {
    refresh.mutate(
      {},
      {
        onSuccess: () => {
          void delivery.refetch()
        },
      }
    )
  }

  const {
    pickup,
    dropoff,
    driver,
    dispatched,
    orderId,
    estimatedPickupTime,
    estimatedDropoffTime,
  } = delivery.data.data

  return (
    <>
      {selectedQuoteIdForDispatch && (
        <DispatchQuoteDialog
          open
          onClose={() => setSelectedQuoteIdForDispatch(undefined)}
          onConfirm={handleConfirm}
        />
      )}
      <Box
        sx={{
          position: 'relative',
          height: 'calc(100vh - 50px)',
          width: 'calc(100% + 44px)',
          overflow: 'hidden',
          mt: -3,
          ml: -3,
        }}
      >
        {/* Fullscreen Map in Background */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        >
          <DeliveryMap delivery={delivery} />
        </Box>

        {/* Floating Card on Left */}
        <Box
          sx={{
            position: isMobile ? 'static' : 'absolute',
            top: isMobile ? 'unset' : 20,
            left: isMobile ? 'unset' : 20,
            zIndex: 1,
            width: isMobile ? '100%' : '30%',
            maxHeight: isMobile ? 'unset' : '90vh',
            overflowY: 'auto',
            boxShadow: 5,
            borderRadius: 3,
          }}
        >
          <Card variant="elevation">
            <CardContent>
              {/* Pickup & Dropoff Location */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    <Person sx={{ mr: 1 }} />
                    Pickup Location
                  </Typography>
                  <List disablePadding>
                    <ListItem>
                      <ListItemText
                        primary={pickup.contactName}
                        secondary={pickup.contactPhone}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary={pickup.address.address} />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    <LocationOn sx={{ mr: 1 }} />
                    Dropoff Location
                  </Typography>
                  <List disablePadding>
                    <ListItem>
                      <ListItemText
                        primary={dropoff.contactName}
                        secondary={dropoff.contactPhone}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText secondary={dropoff.address.address} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>

              {dispatched?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 'bold' }}
                      >
                        Dispatched
                      </Typography>
                    </Grid>
                  </Grid>
                  <DeliveryDispatchedList
                    delivery={delivery}
                    disabled={delivery.isLoading}
                  />
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Delivery Quotes
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <IconButton onClick={onRefreshQuotes}>
                      <Refresh />
                    </IconButton>
                  </Grid>
                </Grid>
                <DeliveryQuoteList
                  delivery={delivery}
                  disabled={refresh.isLoading}
                  onQuoteSelect={openQuoteDialog}
                />
              </Box>

              {/* Driver Details */}
              {driver && driver?.name && (
                <DetailsSection
                  title="Driver Details"
                  items={[
                    {
                      icon: <DirectionsCar />,
                      primary: driver?.name,
                      secondary: `Vehicle: ${driver.vehicleModel} (${driver.vehicleColor})`,
                    },
                    {
                      primary: 'Tracking URL',
                      secondary: (
                        <MuiLink
                          href={driver.trackingUrl}
                          target="_blank"
                          underline="hover"
                          sx={{ color: 'primary.dark' }}
                        >
                          {driver.trackingUrl}
                        </MuiLink>
                      ),
                    },
                  ]}
                />
              )}

              <Divider variant="middle" sx={{ my: 3 }} />

              {/* Additional Details */}
              <DetailsSection
                title="Delivery Window"
                items={[
                  {
                    icon: <AccessTime />,
                    primary: 'Est Pickup Time',
                    secondary: estimatedPickupTime
                      ? new Date(estimatedPickupTime).toLocaleString()
                      : '-',
                  },
                  {
                    icon: <AccessTime />,
                    primary: 'Est Delivery Time',
                    secondary: estimatedDropoffTime
                      ? new Date(estimatedDropoffTime).toLocaleString()
                      : '-',
                  },
                ]}
              />
            </CardContent>
            <CardActions>
              <ButtonGroup fullWidth variant="text">
                <Button color="warning" startIcon={<Cancel />}>
                  Cancel
                </Button>
                {/* <Button color="info" startIcon={<Shuffle />}>
                  Reassign
                </Button> */}
                {orderId && <Button color="success">View Order</Button>}
              </ButtonGroup>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </>
  )
}

export interface DispatchQuoteDialogProps extends DialogProps {
  onConfirm: () => void
}

const DispatchQuoteDialog: FC<DispatchQuoteDialogProps> = (props) => {
  const { onConfirm, ...rest } = props
  return (
    <Dialog fullWidth {...rest}>
      <DialogTitle>Dispatch Delivery</DialogTitle>
      <DialogContent>
        you are about to confirm the delivery dispatch. this will incur the cost
        of the delivery to your account
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => props.onClose?.({}, 'backdropClick')}
        >
          Cancel
        </Button>
        <Button color="success" onClick={() => onConfirm()}>
          Dispatch
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DetailsSection: FC<{
  title: string
  items: {
    icon?: JSX.Element
    secondaryAction?: JSX.Element
    primary: string
    secondary: any
  }[]
}> = ({ title, items }) => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
      {title}
    </Typography>
    <List disablePadding>
      {items.map((item, idx) => (
        <ListItem key={idx} secondaryAction={item.secondaryAction}>
          {item.icon && (
            <ListItemAvatar sx={{ minWidth: 40 }}>{item.icon}</ListItemAvatar>
          )}
          <ListItemText primary={item.primary} secondary={item.secondary} />
        </ListItem>
      ))}
    </List>
  </Box>
)
