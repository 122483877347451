import { FC } from 'react'
import { QueryPicker, QueryPickerProps } from '../QueryPicker'
import { Typography, Box, Checkbox, TextField } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { useIntl } from 'react-intl'

export interface LocationsPickerProps
  extends QueryPickerProps<any, boolean, boolean, boolean> {}

/**
 * Location picker component
 * @returns
 */
export const LocationsPicker: FC<LocationsPickerProps> = (props) => {
  const intl = useIntl()
  return (
    <QueryPicker
      {...props}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: 0 }} key={option.id}>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            sx={{ marginRight: 0, mb: 1 }}
            checked={selected}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">{option.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {option.address.address}
            </Typography>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.formatMessage({ id: 'action_select_location' })}
        />
      )}
    />
  )
}
