import { Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getAccountQueryById,
  getLocationQueryById,
  useLocationCreate,
  useLocationUpdate,
} from '../../../hooks'
import { AppLoader } from '../../../components'
import { useIntl } from 'react-intl'
import { LocationCreateEntity, LocationPatchEntity } from '../../../types'
import {
  defaultLocationState,
  extractLocationCreatePayload,
  extractLocationPatchPayload,
  extractTimeInMinutes,
  findClosestTimePeriod,
  generateTimePeriods,
} from '../utils'
import { fetchTimeZoneData } from '../../util'
import { LocationEditForm } from './LocationEditForm'

export function LocationUpsert() {
  const navigate = useNavigate()
  const intl = useIntl()
  const timePeriods = generateTimePeriods()
  const { locationId } = useParams()
  const account = getAccountQueryById({
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      setAccountData(data?.data)
      !locationId ? handleAddreesState(true, data?.data) : null
    },
  })
  const [accountData, setAccountData] = useState<any>(account?.data?.data)
  const defaultTime = extractTimeInMinutes(timePeriods[1])
  const [autoCompletePeriod, setAutoCompletePeriod] = useState<any>(
    timePeriods[1]
  )
  const [timeZoneFetchError, setTimeZoneFetchError] = useState(false)
  const defaultFormattedTime = defaultTime * 60
  const [locationState, setLocationState] = useState<
    LocationCreateEntity | LocationPatchEntity
  >({
    ...defaultLocationState,
    autoCloseOrders: true,
    autoCloseOrdersAfter: defaultFormattedTime,
  })
  const location = getLocationQueryById(locationId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (data?.data) {
        setLocationState(data.data)
        if (
          data.data.autoCloseOrdersAfter === undefined ||
          (!data.data.autoCloseOrders && data.data.autoCloseOrdersAfter) ||
          !data.data.autoCloseOrders
        ) {
          setAutoCompletePeriod(timePeriods[0])
        } else if (
          (data.data.autoCloseOrders && !data.data.autoCloseOrdersAfter) ||
          data.data.autoCloseOrdersAfter === 0
        ) {
          setAutoCompletePeriod(timePeriods[1])
          setLocationState((state) => ({
            ...state,
            autoCloseOrdersAfter: 1800,
          }))
        } else {
          const timePeriod = findClosestTimePeriod(
            data.data.autoCloseOrdersAfter,
            timePeriods
          )
          setAutoCompletePeriod(timePeriod)
        }
      }
    },
  })

  const locationCreateMutation = useLocationCreate()
  const locationUpdateMutation = useLocationUpdate(locationId)

  const handleLocationChange = async () => {
    locationId
      ? locationUpdateMutation.mutate(
          extractLocationPatchPayload(locationState),
          {
            onSuccess: () => {
              navigate('/app/locations')
            },
          }
        )
      : locationCreateMutation.mutate(
          extractLocationCreatePayload(locationState),
          {
            onSuccess: (data: any) => {
              navigate(`/app/locations/${data.data?.id}/apps`)
            },
          }
        )
  }
  //update the timezone in the location state
  const handleTimeZoneData = (timezoneData: any) => {
    if (timezoneData.error || timezoneData.status === 'INVALID_REQUEST') {
      setTimeZoneFetchError(true)
    } else {
      setLocationState((state: any) => ({
        ...state,
        timezone: timezoneData?.timeZoneId,
      }))
    }
  }
  const handleAddreesState = (checked: boolean, account?: any) => {
    const accountDetails = account || accountData
    if (
      checked &&
      accountDetails.address !== null &&
      accountDetails.address !== undefined
    ) {
      setLocationState((state: any) => ({
        ...state,
        address: {
          address: accountDetails?.address?.formatted_address,
          googlePlaceId: accountDetails?.address?.place_id,
          latitude: accountDetails?.address?.lat,
          longitude: accountDetails?.address?.lng,
        },
        phoneNumber: accountDetails?.phoneNumber,
      }))
    } else {
      setLocationState((state: any) => ({
        ...state,
        address: {},
        phoneNumber: '',
      }))
    }
    //fetch the timezone data for auto filled address from account state
    const fetchTimezone = async () => {
      try {
        if (checked && accountDetails) {
          if (accountDetails.address?.lat && accountDetails.address?.lng) {
            const timezoneData = await fetchTimeZoneData({
              lat: accountDetails.address.lat,
              lng: accountDetails.address.lng,
            })
            handleTimeZoneData(timezoneData)
          }
        }
      } catch (error) {
        console.error('Error in fetching timezone:', error)
      }
    }
    void fetchTimezone()
  }

  if (
    location.isLoading ||
    location.isFetching ||
    locationUpdateMutation.isLoading ||
    locationCreateMutation.isLoading
  ) {
    return <AppLoader />
  }

  if (location?.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }
  return (
    <LocationEditForm
      locationId={locationId}
      handleLocationChange={handleLocationChange}
      myInstance={locationState}
      setMyInstance={setLocationState}
      isAdminAccess={false}
      autoCompletePeriod={autoCompletePeriod}
      setAutoCompletePeriod={setAutoCompletePeriod}
      handleAddreesState={handleAddreesState}
      handleTimeZoneData={handleTimeZoneData}
      timeZoneFetchError={timeZoneFetchError}
      onCancelClick={() => navigate('/app/locations')}
    />
  )
}
