import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link as StyledLink,
} from '@mui/material'
import { useState } from 'react'
import { useLocationMetrics } from '../../../hooks'
import { useParams } from 'react-router'
import { GenericCard } from '../../../components'
import { FormattedNumber, useIntl } from 'react-intl'
import { Chart } from 'react-chartjs-2'
import { getCurrency } from '../../util'
import { Link } from 'react-router-dom'
import { RangeSelector } from '../../../components/RangeSelector'

export function AnalyticsMapping() {
  const { formatNumber, formatMessage } = useIntl()
  // get locationId from the route params
  const { locationId } = useParams<{ locationId: string }>()
  const currency = getCurrency()
  const [range, setRange] = useState('1w')
  const metrics = useLocationMetrics({
    range,
    locationId: locationId!,
  })

  if (metrics.isLoading) {
    return <p>Loading...</p>
  }

  const formatLabelAsMoney = (tooltipItem: any) => {
    return formatNumber(tooltipItem.parsed.y, {
      currency: currency,
      style: 'currency',
    })
  }

  const foramtTickAsMoney = (tickValue: any, index: any, ticks: any) => {
    return formatNumber(+tickValue, { currency: currency, style: 'currency' })
  }

  const catlogItemLink = (productId: string) => {
    return `/app/locations/${locationId}/catalog/product/${productId}/view`
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ flexGrow: 1, float: 'right' }}>
        <RangeSelector
          options={['6h', '1d', '3d', '1w', '1m', '3m', '6m']}
          selected={range}
          onChange={setRange}
        />
      </Box>
      <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
        <Grid item xs={12} md={12} lg={4}>
          <GenericCard
            title="Orders Total"
            subtitle="The total number of orders"
          >
            <Typography variant="h4">
              {metrics.data.aggregations.totalOrders?.value}
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <GenericCard
            title="Total Value"
            subtitle="The total combined value of all orders"
          >
            <Typography variant="h4">
              <FormattedNumber
                value={metrics.data.aggregations.totalOrderValue?.value / 100}
                style="currency"
                currency={currency}
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <GenericCard
            title="Avg Order Value"
            subtitle="The average order value across all orders"
          >
            <Typography variant="h4">
              <FormattedNumber
                value={metrics.data.aggregations.averageOrderValue?.value / 100}
                style="currency"
                currency={currency}
              />
            </Typography>
          </GenericCard>
        </Grid>
        {/* Create a stat box for COMPLETED, REJECTED and CANCELLED orders */}
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={formatMessage({ id: 'dashboard_completedOrders_title' })}
            subtitle={formatMessage({
              id: 'dashboard_completedOrders_subtitle',
            })}
            helpText={formatMessage({
              id: 'dashboard_completedOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  metrics.data.aggregations?.orderStatusVolume?.buckets.find(
                    (bucket: any) => bucket.key === 'COMPLETED'
                  )?.doc_count ?? 0
                }
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={formatMessage({ id: 'dashboard_rejectedOrders_title' })}
            subtitle={formatMessage({
              id: 'dashboard_rejectedOrders_subtitle',
            })}
            helpText={formatMessage({
              id: 'dashboard_rejectedOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  metrics.data.aggregations?.orderStatusVolume?.buckets.find(
                    (bucket: any) => bucket.key === 'REJECTED'
                  )?.doc_count ?? 0
                }
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
          <GenericCard
            title={formatMessage({ id: 'dashboard_cancelledOrders_title' })}
            subtitle={formatMessage({
              id: 'dashboard_cancelledOrders_subtitle',
            })}
            helpText={formatMessage({
              id: 'dashboard_cancelledOrders_helpText',
            })}
          >
            <Typography variant="h4">
              <FormattedNumber
                value={
                  metrics.data.aggregations?.orderStatusVolume?.buckets.find(
                    (bucket: any) => bucket.key === 'CANCELLED'
                  )?.doc_count ?? 0
                }
                style="decimal"
              />
            </Typography>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <GenericCard
            title="Orders over time"
            subtitle="The number of orders over time"
          >
            {/* Display the orderHistogram aggregation in react-chart-2 */}
            <Chart
              options={{
                responsive: true,
                plugins: {
                  legend: { display: false },
                },
                scales: {
                  x: { type: 'timeseries', stacked: true },
                  y: { type: 'linear', ticks: { stepSize: 5 } },
                },
              }}
              data={{
                datasets: [
                  {
                    label: 'Order Count',
                    data: metrics.data.aggregations.orderHistogram?.buckets.map(
                      (bucket: any) => ({
                        x: bucket.key,
                        y: bucket.doc_count,
                      })
                    ),
                  },
                ],
              }}
              height={60}
              type="line"
            ></Chart>
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <GenericCard
            title="Total Revenue by Order Source"
            subtitle="Accumulated total per order source"
          >
            <Chart
              options={{
                plugins: {
                  tooltip: { callbacks: { label: formatLabelAsMoney } },
                },
                responsive: true,
                scales: {
                  x: { title: { text: 'Revenue' } },
                  y: {
                    type: 'linear',
                    ticks: { stepSize: 1, callback: foramtTickAsMoney },
                  },
                },
              }}
              data={{
                labels: ['Revenue'],
                datasets:
                  metrics.data.aggregations.totalOrderValueByOrderSource?.buckets.map(
                    (bucket: any) => ({
                      label: bucket.key,
                      data: [bucket.sum.value / 100],
                    })
                  ),
              }}
              height={60}
              type="bar"
            ></Chart>
          </GenericCard>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <GenericCard
            title="Order by fulfillment type"
            subtitle="The total value of orders by fulfillment type"
          >
            <Chart
              options={{
                responsive: true,
                scales: {
                  // x: { type: 'timeseries', stacked: false },
                  y: { type: 'linear', ticks: { stepSize: 5 } },
                },
              }}
              data={{
                labels: ['Fulfillment Type'],
                datasets:
                  metrics.data.aggregations.totalOrderValueByFulfillemtType?.buckets.map(
                    (bucket: any) => ({
                      label: bucket.key,
                      data: [bucket.doc_count],
                    })
                  ),
              }}
              height={60}
              type="bar"
            ></Chart>
          </GenericCard>
        </Grid>

        {/* Top Products */}
        <Grid item xs={12} md={12} lg={12}>
          <GenericCard
            title="Top Products"
            subtitle="Your locations top selling products"
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Total Sales</TableCell>
                  <TableCell>Total Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metrics.data.aggregations.products.topProducts?.buckets.map(
                  (product: any) => (
                    <TableRow key={product.key}>
                      <TableCell>
                        {/* Link */}
                        <StyledLink
                          component={Link}
                          to={catlogItemLink(product.key)}
                        >
                          {product.details.hits.hits[0]?._source['name'] ??
                            product.key}
                        </StyledLink>
                      </TableCell>
                      <TableCell>{product.doc_count}</TableCell>
                      <TableCell>
                        <FormattedNumber
                          value={(product.totalRevenue?.value ?? 0) / 100}
                          style="currency"
                          currency={currency}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </GenericCard>
        </Grid>
      </Grid>
    </Box>
  )
}
